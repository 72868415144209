@import "./ca-color-palette.scss";

.ag-popup {
    .ag-popup-child {
        z-index: 5000;
    }
}

.ag-row-version {
    background-color: #eeeeee !important;
}

.ag-deleted {
    background-color: rgb(230, 181, 181) !important;
}

.ag-theme-balham {
    --ag-balham-active-color: #3cabc9; // $aqua
    --ag-selected-row-background-color: rgba(60, 171, 201, 0.15); // $aqua + alpha
    --ag-font-size: 12px;
    --ag-font-family: "proxima-nova"; // $font-family-sans-serif
    --ag-header-foreground-color: #333333; // $dark-grey
    --ag-header-background-color: #fff; // $white
    --ag-input-border-color: #dddddd;
    --ag-input-focus-border-color: #3cabc9; // $aqua
    --ag-header-column-separator-display: block;
    --ag-header-column-separator-height: 100%;
    --ag-header-column-separator-color: #dddddd;
    --ag-header-column-resize-handle-display: none;
    --ag-border-color: #dddddd;

    // font-family: $font-family-sans-serif !important;
    font-family: "Source Sans Pro" !important;
    .ag-header {
        width: fit-content !important;
    }

    .ag-header-row {
        text-transform: uppercase;

        &.ag-header-row-column-group {
            
        }
    }
    
    .ag-header-group-cell-label {
        justify-content: center !important;
    }
    
    .ag-header-group-cell-with-group{
        border-bottom: 1px #d3d3d3 solid !important;
    }
    
    .ag-header-cell-label {
        flex: none !important;
        align-items: end !important;
    }

    .ag-cell-label-container {
        height: 35px !important;
    }

    .three-text-break-right > div > div.ag-header-cell-label {
        flex: none !important;
        word-wrap: break-word;
        width: 55px !important;
        text-align: end;
    }

    .long-text-break-right > div > div.ag-header-cell-label {
        flex: none !important;
        word-wrap: break-word;
        width: 100% !important;
        text-align: end;
    }

    .four-text-break-right > div > div.ag-header-cell-label {
        flex: none !important;
        word-wrap: break-word;
        width: 110px !important;
        text-align: end;
    }

    .five-text-break-right > div > div.ag-header-cell-label {
        flex: none !important;
        word-wrap: break-word;
        width: 107px !important;
        text-align: end;
    }

    .six-text-break-right > div > div.ag-header-cell-label {
        flex: none !important;
        word-wrap: break-word;
        width: 130px !important;
        text-align: end;
    }

    .seven-text-break-right > div > div.ag-header-cell-label {
        flex: none !important;
        word-wrap: break-word;
        width: 133px !important;
        text-align: end;
    }

    .text-break-right > div > div.ag-header-cell-label {
        flex: none !important;
        word-wrap: break-word;
        width: 95px !important;
        text-align: end;
    }

    span.ag-cell-value > a {
        @media screen and (max-width: 500px) {
            word-wrap: break-word !important;
            white-space: pre-line !important;
        }
    }
    span.ag-cell-value {
        @media screen and (max-width: 500px) {
            word-wrap: break-word !important;
            white-space: pre-line !important;
            overflow: unset !important;
            text-overflow: unset !important;
        }
    }
    .ag-header-group-cell::after {
        width: 0px !important;
    }

    .ag-header-cell {
        border-top: none !important;
    }

    .ag-header-cell::after{
        width: 0px !important;
    }

    .ag-floating-filter-body {
        .ag-input-field-input {
            border-radius: 0;

            &.ag-text-field-input {
                line-height: 16px;
                padding-top: 2px;
                padding-bottom: 2px;
            }

            &:focus {
                box-shadow: none !important;
                border: 1px solid $aqua;
            }
        }
    }

    .ag-filter {
        .ag-input-field-input {
            border-radius: 0;

            &.ag-text-field-input {
                line-height: 16px;
                padding-top: 2px;
                padding-bottom: 2px;
            }

            &:focus {
                box-shadow: none !important;
                border: 1px solid $aqua;
            }
        }

        .ag-picker-field-wrapper {
            border-radius: 0;
            border: 1px solid $borders-and-inactive !important;

            &:focus {
                box-shadow: none;
            }

            &[aria-expanded="true"] {
                border: 1px solid $aqua !important;
            }
        }
    }

    .ag-ltr .ag-cell {
        border-right: 0px solid $borders-and-inactive !important;
        .ag-cell-value {
            font-size: 14px !important;
            font-family: "proxima-nova";
            color: #323130;
        }
    }

    .ag-header-container {
        white-space: unset !important;
    }
    .ag-header {
        border-bottom: 1px solid $aggrid-header-border-bottom !important;
        text-align: center !important;
        white-space: unset !important;
        .ag-header-row {
            word-break: break-word !important;
            .ag-header-cell {
                .ag-header-cell-text {
                    white-space: unset !important;
                    color: #323130;;
                    font-family: 'proxima-nova';
                    font-size: 12px;
                    font-weight: 700;
                }
            }

            .ag-header-cell::after,
            .ag-header-group-cell::after {
                top: 0 !important;
                height: 100% !important;
            }
        }
    }

    .ag-root-wrapper-body.ag-layout-normal {
        height: auto !important;
    }
    .ag-pinned-left-header {
        border-right: 0 !important;
    }
}
