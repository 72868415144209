// BLUE
$ca-blue-darkest: #061d40;
$ca-blue-darker: #0c2c5e;
$ca-blue-dark: #092B60;
$ca-blue-primary: #0c3980;
$ca-blue-secondary: #347bec;
$ca-blue-light: #0078d4;
$ca-blue-light-alt: #eff6fc;
$ca-blue-light-hover: #106ebe;

// GRAY
$ca-gray-dark: #525457;
$ca-gray-border: #edebe9;
$ca-gray-text: #201f1e;
$ca-gray-hover: #f3f2f1;
$ca-gray-light: #D3D3D3;

// BLACK
$ca-black-light: #323130;

// WHITE
$ca-white: white;

// UX
$ca-severe-warning: #D83B01;
$ca-form-error:  #A4262C;
$ca-error-background: rgba(245, 135, 145, 0.2);
