$breakpoints: (
  xxs: 'screen and ( min-width: 375px )',
  xs: 'screen and ( min-width: 480px )',
  sm: 'screen and ( min-width: 768px )',
  mmd: 'screen and ( min-width: 1219px )',
  md: 'screen and ( min-width: 1024px )',
  lg: 'screen and ( min-width: 1440px )',
  xl: 'screen and ( min-width: 1600px )',
  // to max-width add ms_ before the param
  mx_xxs: 'screen and ( max-width: 375px )',
  mx_xs: 'screen and ( max-width: 480px )',
  mx_sm: 'screen and ( max-width: 768px )',
  mx_md: 'screen and ( max-width: 1024px )',
  mx_lg: 'screen and ( max-width: 1440px )',
  mx_xl: 'screen and ( max-width: 1600px )',

);

@mixin breakpoint($size) {
  $breakpoint-found: map-has-key($breakpoints, $size);

  @if $breakpoint-found == true {
    $breakpoint: map-get($breakpoints, $size);

    @media #{$breakpoint} {
      @content;
    }
  } @else {
    @warn "Breakpoint size " + #{$size} + " doesn't exist.";
  }
}
