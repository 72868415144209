@font-face {
  font-family: "proxima-nova";
  src: url("./shared/assets/font/ProximaNova/ProximaNova-Light.otf") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "proxima-nova";
  src: url("./shared/assets/font/ProximaNova/ProximaNova-LightItalic.otf") format("opentype");
  font-display: swap;
  font-style: italic;
  font-weight: 200;
}
@font-face {
  font-family: "proxima-nova";
  src: url("./shared/assets/font/ProximaNova/ProximaNova-Regular.otf") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "proxima-nova";
  src: url("./shared/assets/font/ProximaNova/ProximaNova-RegularItalic.otf") format("opentype");
  font-display: swap;
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: "proxima-nova";
  src: url("./shared/assets/font/ProximaNova/ProximaNova-Regular.otf") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "proxima-nova";
  src: url("./shared/assets/font/ProximaNova/ProximaNova-RegularItalic.otf") format("opentype");
  font-display: swap;
  font-style: italic;
  font-weight: 500;
}
@font-face {
  font-family: "proxima-nova";
  src: url("./shared/assets/font/ProximaNova/ProximaNova-Semibold.otf") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "proxima-nova";
  src: url("./shared/assets/font/ProximaNova/ProximaNova-Bold.otf") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "proxima-nova";
  src: url("./shared/assets/font/ProximaNova/ProximaNova-Black.otf") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 800;
}
@font-face {
  font-family: "proxima-nova";
  src: url("./shared/assets/font/ProximaNova/ProximaNova-Extrabold.otf") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 900;
}

html {
  font-size: 62.5%; /* 62.5% of 16px = 10px */
}

.ms-Layer--fixed {
  z-index: 1200;
}